/*------------- #FRIENDS-HARMONIC --------------*/



.friends-harmonic {
  margin-bottom: 0;
  overflow: hidden;

  a {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	overflow: hidden;
	border: 2px solid $white-color;
	display: block;
	margin-left: -12px;
  }

  li {
	float: left;

	&:first-child a {
	  margin-left: 0;
	}
  }

  .with-text {
	margin-top: 5px;
	margin-left: 15px;
  }

  .all-users {
	line-height: 26px;
	opacity: .8;

	&:hover {
	  color: $white-color;
	}
  }
}

.friends-harmonic-wrap {
  display: flex;
  align-items: center;

  & + .comments-shared {
	margin-top: 15px;
  }
}