/*------------- #YOUR-PROFILE--------------*/



.items-round-little {
  color: $white-color;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  width: 19px;
  height: 19px;
  line-height: 19px;
  border-radius: 100%;
  margin-left: 12px;

  &:hover {
	color: $white-color;
  }
}

.your-profile-menu {
  padding: 12px 25px;
  border-bottom: 1px solid $border-color;
  margin-bottom: 0;

  li {
	a {
	  font-size: 11px;
	  padding: 12px 0;
	  color: $body-font-color;
	  display: block;
	  font-weight: 700;

	  &:hover {
		color: $heading-color;
	  }
	}
  }
}

.profile-settings-responsive {
  width: 250px;
  min-height: 100vh;
  position: fixed;
  top: 70px;
  transform: translateX(-100%);
  z-index: 21;
  background-color: $white-color;
  transition: all .3s ease;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
  .fa-angle-left {
	display: none;
  }
  &.open {
	transform: translateX(0);
	.fa-angle-right {
	  display: none;
	}
	.fa-angle-left {
	  display: block;
	}
  }
  .ps > .ps__scrollbar-y-rail {
	opacity: 0;
	visibility: hidden;
	z-index: -4444;
  }
  .mCustomScrollbar {
	max-height: calc(100vh - 70px);
  }
}

.profile-settings-open {
  cursor: pointer;
  position: absolute;
  left: 100%;
  top: -webkit-calc(50% - 25px);
  top: calc(50% - 25px);
  background-color: $white-color;
  -webkit-box-shadow: 1px 0 3px rgba(0,0,0,.2);
  box-shadow: 1px 0 3px rgba(0,0,0,.2);
  border-radius: 0 100px 100px 0;
  line-height: 1;
  padding: 20px 8px 20px 8px;
  font-size: 20px;
}



/*------------- Responsive mode styles --------------*/

@media (max-width: 768px) {
  .profile-settings-responsive {
	visibility: visible;
	opacity: 1;
  }

  .responsive-display-none {
	display: none;
  }
}