/*------------- #TABLE OF CONTENTS --------------*/
/*------------------------------------------------------------------


Project name: "Olympus",
Version: "1.0.0",
Description: "HTML-template"


1. Bootstrap core

	../Bootstrap/scss/

	1.1 Alert
	1.2 Badge
	1.3 Breadcrumb
	1.4 Button group
	1.5 Buttons
	1.6 Card
	1.7 Carousel
	1.8 Close
	1.9 Code
	1.10 Custom
	1.11 Custom forms
	1.12 Dropdown
	1.13 Forms
	1.14 Grid
	1.15 Images
	1.16 Input group
	1.17 Jumbotron
	1.18 List Group
	1.19 Media
	1.20 Mixins
	1.21 Modal
	1.22 Nav
	1.23 Navbar
	1.24 Normalize
	1.25 Pagination
	1.26 Popover
	1.27 Print
	1.28 Progress
	1.29 Reboot
	1.30 Responsive embed
	1.31 Tables
	1.32 Tooltip
	1.33 Transitions
	1.34 Type
	1.35 Utilities
	1.36 Variables


1. Theme Styles

	../sass/theme-styles/

	1.1 Bootstrap Customization
	1.2 Header
	1.3 Mobile App
	1.4 Variables (base colors, fonts ...)


2. Blocks

	../sass/blocks/

	2.1 UI-blocks
	2.2 Buttons
	2.3 Forms
	2.4 Fixed sidebars
	2.5 Skills
	2.6 Friends
	2.7 Sliders
	2.8 Pagination
	2.9 Landing page
	2.10 Registration Login Form
	2.11 Main Header
	2.12 Your Profile
	2.13 Notification
	2.14 Popup Chat
	2.15 Overlay Menu
	2.16 Popup Windows
	2.17 Widget search
	2.18 Widget personal info
	2.19 Widget socials
	2.20 Widget select
	2.21 Widget contacts
	2.22 Widget fav-ed page
	2.23 Widget twitter
	2.24 Widget latest photo
	2.25 Widget playlist
	2.26 Widget friend pages added
	2.27 Widget create fav page
	2.28 Widget birthday alert
	2.29 Widget action
	2.30 Widget activity feed
	2.31 Widget weather
	2.32 Widget last video
	2.33 Widget pool
	2.34 Widget badges
	2.35 Widget blog posts
	2.36 OL lists
	2.37 Friends Harmonic
	2.38 Posts
	2.39 Comment List
	2.40 Post Video
	2.41 Video Player
	2.42 Video news feed form
	2.43 Photo gallery
	2.44 Features Video
	2.45 Event item
	2.46 Available widget
	2.47 Today events
	2.48 Calendar events tabs
	2.49 Helper / Additional classes
	2.50 Statistics





-------------------------------------------------------------------*/