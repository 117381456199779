/*------------- #HEADER --------------*/


.header {
  height: 70px;
  background-color: $secondary-color;
  padding-right: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 21;

  .page-title {
	padding: 26px 50px 26px 100px;
	text-transform: uppercase;
	float: left;
	height: 100%;
	min-width: 170px;

	& > * {
	  color: $white-color;
	  margin: 0;
	}
  }

  &.header--logout {
	display: flex;
	align-items: center;
	padding-right: 0;

	.page-title {
	  padding: 26px;
	}

	.header-content-wrapper {
	  width: 100%;
	  display: flex;
	  align-items: center;
	}

	.logo {
	  min-height: 70px;
	  min-width: 70px;
	  height: 70px;
	  width: 70px;
	  background-color: $primary-color;
	  padding: 15px;
	}
  }
}

.header-content-wrapper {
   height: 100%;
}

.header-spacer {
  display: block;
  height: 110px;
}

.header-spacer-small {
  height: 70px;
}

.header-spacer--standard {
  height: 143px;
}

.search-bar {
  float: left;
  width: 500px;
  height: 70px;
  background-color: #494c62;
  position: relative;

  &.w-search {
	width: 500px;
	min-height: 70px;
	background-color: #494c62;
  }

  .form-group {
	margin-bottom: 0;
	height: 70px;
  }

  .form-control {
	border: none;
	padding: 0;
  }

  .selectize-control {
	position: relative;
	z-index: 20;
	height: 70px;
  }

  .selectize-dropdown {
	background-color: $white-color;
	z-index: 50;
	border: 1px solid #e6ecf5;
	box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
  }

  .selectize-input {
	height: 70px;

	&.has-items  {

	  input {
		background-color: transparent;
	  }

	& > * {
	  padding: 3px 10px;
	  border-radius: 3px;
	  background-color: #9a9fbf;
	  color: $white-color;
	  display: inline-block;
	  margin-right: 3px;

	  &:first-child {
		margin-left: 15px;
	  }
	}

	}
  }


  .form-group.with-button {

	button {
	  color: #696d87;
	  fill: #696d87;
	  background-color: #494c62;
	  width: 50px;

	  svg {
		height: 22px;
		width: 22px;
	  }
	}

	input {
	  height: 100%;
	  border: none;
	  color: #9a9fbf;
	}
  }

  .notification-list {
	position: absolute;
	top: -100%;
	visibility: hidden;
	opacity: 0;
	transition: all .3s ease;
	z-index: 50;
	background: #fff;
	width: 100%;
	box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
	border-right: 1px solid $border-color;
	border-left: 1px solid $border-color;
  }

  .notification-list .notification-icon svg {
	width: 20px;
	height: 20px;
  }
}

.control-block {
  float: right;
  height: 100%;
  display: flex;
  align-items: center;

  .author-thumb {
	margin-right: 8px;
	position: unset;
  }

  .author-title {
	font-weight: 700;
	font-size: 12px;
	color: $white-color;

	& > svg {
	  margin-left: 15px;
	  fill: $white-color;
	  width: 6px;
	  height: 4px;
	}
  }

  .author-subtitle {
	display: block;
	font-weight: 700;
	color: #9a9fbf;
	font-size: 8px;
  }
}

.link-find-friend {
  padding: 25px 20px;
  color: $white-color;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}

.control-icon {
  margin-right: 29px;
  fill: $icon-color;
  margin-top: 8px;

  & > svg {
	height: 20px;
  }

  &.has-items {
	fill: $white-color;
  }

  .label-avatar {
	width: 19px;
	height: 19px;
	line-height: 19px;
	top: -12px;
	right: -9px;
  }

  .more-dropdown {
	width: 380px;
	top: 35px;
	right: -180px;
	padding: 0 0 0 0;

	.view-all {
	  padding: 20px;
	  color: $white-color;

	  &:hover {
		color: $white-color;
	  }
	}

    &.with-bottom {
        padding-bottom:64px;
    }

	.notification-list {
	  padding: 0;
	}
  }

  .ui-block-title {
	a {
	  display: inline-block;
	  float: right;
	  margin-left: 20px;
	  text-transform: uppercase;
	  font-size: 9px;
	  padding: 0;
	}
  }

  .accept-request {
	padding: 0.5rem;
	font-size: 0.688rem;
	color: $white-color;
	display: inline-block;

	&:hover {
	  color: $white-color;
	}
  }

  .notification-list {

	.author-thumb {
	  height: 34px;
	  width: 34px;
	  margin-right: 0;
	  overflow: hidden;
	}

	.notification-friend {
	  padding: 0;
	  font-size: 14px;
	}
  }

  .notification-list {

	.notification-link {
	  padding: 0;
	  display: inline-block;
	}

	.notification-event {
	  width: 75%;
	}
  }

  .notification-list.friend-requests .accepted {

	.notification-link {
	  padding: 0;
	  display: inline-block;
	}

	.notification-icon {
	  margin-top: 0;
	}

  }

  .notification-list.friend-requests .notification-event {
	width: 60%;
  }

  .notification-list .comment-photo {

	margin-top: 15px;
	max-width: 215px;

	span {
	  font-size: 11px;
	}
  }

  .notification-list li > * {
	vertical-align: top;
  }

  .notification-list .notification-icon  {
	margin-top: 0;

	svg {
	  height: 20px;
	  width: 20px;
	}
  }

  .chat-message li.chat-group .author-thumb {
	width: 36px;
  }

  .notification-list.chat-message .notification-icon svg {
	height: 24px;
	width: 24px;
  }

  .mCustomScrollbar {
	overflow: hidden;
	max-height: 300px;
  }

  .ps > .ps__scrollbar-y-rail, .ps:hover > .ps__scrollbar-y-rail {
	left: 0;
	top: 40px !important;
  }
}


.view-all {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 20;
  padding: 20px;
  color: $white-color;
  font-size: 12px;
  font-weight: 700;
  display: block;
  text-align: center;
  border-radius: 0 0 5px 5px;

  &:hover {
	color: $white-color;
  }
}

.author-page {
  margin-left: 50px;

  .more-dropdown {
	width: 230px;
	top: calc(100% + 5px);
	right: -30px;
	padding: 0;
  }

  .chat-settings {
	padding: 15px 40px;

	a {
	  color: $body-font-color;

	  &:hover {
		color: $heading-color;
	  }
	}

	.icon-status {
	  margin-right: 20px;
	  position: relative;
	  border: none;
	}
  }

  .account-settings {
	padding: 15px 25px;
	margin-bottom: 0;

	a {
	  padding: 13px 0;
	}
  }

  .mCustomScrollbar {
	max-height: calc(100vh - 40px);
  }
}

.account-settings {
  padding: 15px 25px;
  margin-bottom: 0;

  a {
	padding: 13px 0;
	font-size: 12px;
	color: #515365;
	display: block;
	font-weight: 700;

	&:hover {
	  color: $primary-color;
	}

	& > * {
	  display: inline-block;
	  vertical-align: middle;
	}

  }
}

.custom-status {
  margin: 15px 25px;

  input {
	padding: 0.5rem 1.1rem;
	font-size: 12px;
  }

  &.form-group.with-button button {
	border-radius: 0 5px 5px 0;
	background-color: $purple;
	overflow: hidden;

	svg {
	  fill: $white-color;
	  width: 14px;
	  height: 12px;
	}
  }
}

.account-settings {

  a {
	padding: 13px 0;
  }

  svg {
	margin-right: 15px;
	fill: $icon-color;
	width: 20px;
	height: 20px;
  }
}




/*--------TOP-HEADER--------*/


.top-header {
  position: relative;

  &.top-header-favorit {
	.top-header-author {
	  transform: translate(0);
	  left: 100px;
	  bottom: -40px;
	  z-index: 5;
	  max-width: unset;
	}

	.author-thumb {
	  float: left;
	  margin-right: 30px;
	}

	.author-content {
	  text-align: left;
	  margin-top: 20px;
	  float: left;
	  color: $white-color;
	}

	.author-name {
	  color: $white-color;
	}
  }
}

.top-header-thumb {
	position: relative;

  img {
	width: 100%;
	border-radius: 5px 5px 0 0;
	overflow: hidden;
  }

  &:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: inset 0px -120px 55px -30px rgba(0,0,0,0.5);
  }

}

.profile-menu {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;

  li {

	& > div, a {
	  font-size: 14px;
	  font-weight: 700;
	  color: $grey-lighter;
	  display: block;

	  &:hover {
		color: $heading-color;
	  }

	  &.active {
		color: $heading-color;
	  }
	}
  }

  .more-dropdown {
	width: 230px;
	top: calc(100% + 10px);
	right: -25px;

	li {
	  display: block;
	}
  }

   li .more-dropdown a {
	 padding: 10px 0;
	 font-size: 13px;
  }

  .friend-count-item {
	text-align: center;

	.title {
	  font-weight: 400;
	  font-size: 12px;
	}
  }
}

.top-header-author {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 15px;
  text-align: center;
  max-width: 200px;
  z-index: 4;

  .author-thumb {
	border-radius: 100%;
	border: 6px solid $white-color;
	margin: 0 auto;
	overflow: hidden;
	width: 132px;
	height: 132px;
	background-color: #FDFBEE;

	img {
	  border-radius: 0;
	}
  }

  .author-name {
	font-weight: 700;
  }
}

.profile-section {
  position: relative;
  padding: 38px 0;
  z-index: 3;

  .control-block-button {
	position: absolute;
	right: 38px;
	top: -31px;
  }
}

#site-header { @include admin-sticky-fix(0) }


/*------------- #Styles for svg-icons --------------*/

[class^="olymp-"], [class*=" -icon"] {
  height: 26px;
  width: 26px;
  display: inline-block;
}

.post__likes-small {
  width: 16px;
  height: 14px;
}

.post__shared-small {
  width: 24px;
  height: 21px;
}

.post__comments-small {
  width: 18px;
  height: 16px;
}

.post__likes {
  width: 20px;
  height: 18px;
}

.post__comments {
  width: 22px;
  height: 20px;
}

.post__shared {
  width: 26px;
  height: 21px;
}

.fav-icon {
  width: 20px;
  height: 20px;
}

.arrow-down-icon {
  width: 6px;
  height: 4px;
}

.olymp-three-dots-icon {
  width: 16px;
  height: 4px;
}

.olymp-little-delete {
  width: 8px;
  height: 8px;
}

.olymp-trophy-icon {
  width: 18px;
  height: 16px;
}

.fixed-sidebar.fixed-sidebar-responsive {
  display: none;
  height: auto;
  min-height: 70px;
  transition: all .3s ease;

  &.open {
	min-height: 1000vh;
  }
}

.header-responsive {
  display: none;

  .header-content-wrapper {
	z-index: 5;
  }
}

.tab-content-responsive {
  display: none;

  .tab-pane {
	background-color: $white-color;
  }

  .search-bar.w-search {
	background-color: #494c62;
  }

  .mCustomScrollbar {
	max-height: calc(100vh - 140px);
	overflow-y: auto !important;
  }

}

.header-menu {
  float: left;
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  background-color: $white-color;

   ul {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 0;
	height: 100%;
  }

  a {
	font-weight: 700;
	font-size: 13px;
	position: relative;
	color: $heading-color;
	padding: 30px;
	display: block;
	line-height: 1;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
	background-color: transparent;
	cursor: pointer;
	color: inherit;
  }

  .nav-link:hover {
	color: $primary-color;
  }

  .dropdown:hover > .dropdown-menu {
	visibility: visible;
	opacity: 1;
  }

  .dropdown-menu {
	visibility: hidden;
	opacity: 0;
	display: block;
	transition: all .3s ease;
	margin: 0;
	padding: 0;
	background-color: $secondary-color;
	border-color: $border-color;
	box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
  }

  .dropdown-item:focus, .dropdown-item:hover {
	color: $primary-color;
	background-color: transparent;
  }

  &.open {
	 transform: translateX(70px);
  }

  .dropdown-toggle::after {
	right: 0;
	top: auto;
  }

  .menu-search-item {
	a {
	  padding-left: 30px;
	}
  }
}

.header--standard-wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.header--standard {
  background-color: $white-color;
  width: calc(100% - 142px);
  position: fixed;
  left: 71px;
  padding: 35px 55px;
  box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
  z-index: 19;

  .logo {
	color: $heading-color;

	.img-wrap {
	  margin-right: 13px;
	}

	&:hover {
	  color: $heading-color;
	}
  }

  .header-menu {
	margin-left: auto;

	.dropdown-item {
	  padding: 10px 20px;
	  font-weight: 500;

	  &:hover {
		background-color: $body-bg;
	  }
	}

	.shoping-cart {
	  visibility: visible;
	  opacity: 1;
	}
  }

  .dropdown-menu {
	background-color: $white-color;
  }

  &.headroom--not-top {
	padding:  0 55px;
  }

  .ps {
	-ms-touch-action: unset;
	touch-action: unset;
	overflow: unset !important;
	-ms-overflow-style: unset;
  }

  .ps:hover > .ps__scrollbar-x-rail, .ps:hover > .ps__scrollbar-y-rail {
	display: none;
  }

  &.header--standard-landing {
	width: 100%;
	left: auto;
	top: 0;
  }

  &.header--standard-full-width {
	width: 100%;
	left: auto;
	top: 0;
  }

  .header-menu .close-responsive-menu {
	display: none;
  }

  &.header-bg-light {
	background-color: $white-color;
  }
}

.header--standard-dark {
	background-color: $secondary-color;

  .logo {
	color: $white-color;

	.logo-title {
	  color: $white-color;
	}

	&:hover {
	  color: $white-color;
	}
  }

  .column-tittle {
	color: $white-color;
  }

  .header-menu {
	background-color: $secondary-color;

	a {
	  color: $white-color;
	}

	.dropdown-item {
	  &:hover {
		background-color: lighten($secondary-color, 10%);
	  }
	}
  }

  .dropdown-menu {
	background-color: $secondary-color;
	border-color: lighten($secondary-color, 5%);
  }
}

.open-responsive-menu {
  margin-left: auto;
  display: none;
  fill: $icon-color;

  &:hover {
	fill: $primary-color;
  }
}

.close-responsive-menu {
  position: absolute;
  top: 20px;
  left: 25px;
  fill: $icon-color;
  display: none;
  cursor: pointer;
  transition: all .3s ease;

  svg {
	width: 16px;
	height: 16px;
  }

  &:hover {
	fill: $primary-color;
  }
}

.dropdown-has-megamenu {
  position: static;
}

.dropdown-menu.megamenu {
  width: 100%;
  padding: 40px;

  .dropdown-item {
	border-radius: 5px;
	overflow: hidden;
	background-color: transparent;

	&:hover {
	  padding-left: 25px;
	  background-color: transparent;
	}
  }
}

.column-tittle {
  padding-left: 20px;
  padding-bottom: 15px;
  position: relative;
  overflow: hidden;
  display: inline-block;

 &:after {
   content: "";
   display: block;
   height: 2px;
   background-color: $primary-color;
   width: 15%;
   position: absolute;
   bottom: 0;
   left: 20px;
   transition: width .3s ease;
 }

  &:hover:after {
	width: 100%;
  }
}

.header--standard .mCustomScrollbar {
  max-height: calc(100vh - 70px);
}



/*--------HEADER-LANDING--------*/

.header--standard-landing {
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  color: $white-color;

  svg {
	width: 18px;
	height: 18px;
  }

  .logo {
	float: left;
	background-color: transparent;
	padding: 0;
	color: $white-color;

	.img-wrap {
	  margin-right: 13px;
	  transition: opacity .3s ease;
	}

	.logo-colored {
	  position: absolute;
	  left: 0;
	  top: 0;
	  opacity: 0;
	}

	.logo-title {
	  color: $white-color;
	}

	&:hover {
	  color: inherit;
	}
  }

  .dropdown-toggle::after {
	border-top-color: $white-color;
  }

  .dropdown-menu {
	background-color: $white-color;
  }

  .dropdown-menu.megamenu .dropdown-item:hover {
	color: $primary-color;
  }

  .header-menu {
	float: right;
	background-color: transparent;

	.js-expanded-menu .olymp-close-icon {
	  position: absolute;
	  right: 30px;
	  top: 0;
	  opacity: 0;
	  visibility: hidden;
	}

	&.expanded-menu {
	  .nav-item {
		visibility: visible;
		opacity: 1;
	  }

	  .olymp-menu-icon {
		opacity: 0;
		visibility: hidden;
	  }

	  .olymp-close-icon {
		opacity: 1;
		visibility: visible;
	  }
	}

	&.open {
	  transform: translateX(0);
	}
  }

  .header-menu {

	.nav-item {
	  display: block;
	  visibility: hidden;
	  opacity: 0;
	}

	.nav-item, li {

	  a {
		color: $white-color;
		fill: $white-color;
		display: block;
	  }

	  &.js-expanded-menu {
		visibility: visible;
		opacity: 1;
		position: relative;

		a {
		  padding: 0 30px;
		}
	  }

	  .nav-link:hover {
		color: $white-color;
	  }

	  .dropdown-item {
		color: $body-font-color;

		&:hover {
		  color: $primary-color;
		}
	  }
	}
  }

  .header-menu li:first-child > a {
	padding-left: 0;
  }

  .header-menu li:last-child > a {
	padding-right: 0;
  }

  .open-responsive-menu,
  .close-responsive-menu {
	fill: $white-color;
  }

  &.headroom--not-top {
	background-color: $white-color;
	box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.2);

	.logo {
	  color: $heading-color;
	}

	.open-responsive-menu {
	  fill: $icon-color;

	  &:hover {
		fill: $primary-color;
	  }
	}

	.logo .logo-colored {
	  opacity: 1;
	}

	.logo .logo-title {
	  color: $heading-color;
	}

	.header-menu .nav-item a,
	.header-menu li .nav-link {
	  color: $heading-color;
	  fill: $icon-color;

	  &:hover {
		color: $heading-color;
		fill: $icon-color;
	  }
	}

	.header-menu li .dropdown-item {
	  color: $body-font-color;
	}

	.shoping-cart .count-product {
	  color: $icon-color;
	}
  }
}

.landing-page {
  position: relative;
  min-height: 100vh;

  .content-bg-wrap {
	background-image: url("../img/landing-users.png");
	background-position: 0 0;
	background-repeat: repeat;
	animation: sidedownscroll 30s linear infinite;
	background-size: auto;

	&:before {
	  background-color: rgba(255,94,58,.95);
	}

	@keyframes sidedownscroll {
	  0% {
		background-position: 0 0;
	  }
	  100% {
		background-position: -2000px 2000px;
	  }
	}
  }

  .header-spacer--standard {
	margin-bottom: 40px;
  }
}

.content-bg-wrap {
  background-position: 0 0;
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  box-sizing: border-box;
  animation: slide 50s linear infinite;
  will-change: background-position;
  background-size: auto;

  &:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 1;
	z-index: auto;
  }

  &.bg-landing {
	&:before {
	  background-color: rgba(255,94,58,.95);
	}
  }

  @keyframes sidedownscroll {
	0% {
	  background-position: 0 0;
	}
	100% {
	  background-position: -2000px 2000px;
	}
  }
}

@-webkit-keyframes slide {
  from { background-position: 0 0; }
  to { background-position: -4000px 0; }
}
@-moz-keyframes slide {
  from { background-position: 0 0; }
  to { background-position: -4000px 0; }
}
@-webkit-keyframes slide {
  from { background-position: 0 0; }
  to { background-position: -4000px 0; }
}
@keyframes slide {
  from { background-position: 0 0; }
  to { background-position: -4000px 0; }
}

.without-animation {
  animation: none;
}

.tag-label {
  display: inline-block;
  border-radius: 3px;
  padding: 4px;
  color: $white-color;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 800;
  margin-left: 12px;
  top: -3px;
  position: relative;
}

/*================= Styles for sticky-Header =========*/


.animated {
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  will-change: transform,opacity;
}

@-webkit-keyframes swingInX {
  0% {
	-webkit-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(0deg)
  }
}
@-moz-keyframes swingInX {
  0% {
	-moz-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-moz-transform:perspective(400px) rotateX(0deg)
  }
}
@-o-keyframes swingInX {
  0% {
	-o-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-o-transform:perspective(400px) rotateX(0deg)
  }
}
@keyframes swingInX {
  0% {
	transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	transform:perspective(400px) rotateX(0deg)
  }
}
.animated.swingInX {
  -webkit-transform-origin:top;
  -moz-transform-origin:top;
  -ie-transform-origin:top;
  -o-transform-origin:top;
  transform-origin:top;
  -webkit-backface-visibility:visible !important;
  -webkit-animation-name:swingInX;
  -moz-backface-visibility:visible !important;
  -moz-animation-name:swingInX;
  -o-backface-visibility:visible !important;
  -o-animation-name:swingInX;
  backface-visibility:visible !important;
  animation-name:swingInX;
}
@-webkit-keyframes swingOutX {
  0% {
	-webkit-transform:perspective(400px) rotateX(0deg)
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(-90deg)
  }
}
@-moz-keyframes swingOutX {
  0% {
	-moz-transform:perspective(400px) rotateX(0deg)
  }
  100% {
	-moz-transform:perspective(400px) rotateX(-90deg)
  }
}
@-o-keyframes swingOutX {
  0% {
	-o-transform:perspective(400px) rotateX(0deg)
  }
  100% {
	-o-transform:perspective(400px) rotateX(-90deg)
  }
}
@keyframes swingOutX {
  0% {
	transform:perspective(400px) rotateX(0deg)
  }
  100% {
	transform:perspective(400px) rotateX(-90deg)
  }
}
.animated.swingOutX {
  -webkit-transform-origin:top;
  -webkit-animation-name:swingOutX;
  -webkit-backface-visibility:visible !important;
  -moz-animation-name:swingOutX;
  -moz-backface-visibility:visible !important;
  -o-animation-name:swingOutX;
  -o-backface-visibility:visible !important;
  animation-name:swingOutX;
  backface-visibility:visible !important;
}
@-webkit-keyframes slideDown {
  0% {
	-webkit-transform:translateY(-100%)
  }
  100% {
	-webkit-transform:translateY(0)
  }
}
@-moz-keyframes slideDown {
  0% {
	-moz-transform:translateY(-100%)
  }
  100% {
	-moz-transform:translateY(0)
  }
}
@-o-keyframes slideDown {
  0% {
	-o-transform:translateY(-100%)
  }
  100% {
	-o-transform:translateY(0)
  }
}
@keyframes slideDown {
  0% {
	transform:translateY(-100%)
  }
  100% {
	transform:translateY(0)
  }
}
.animated.slideDown {
  -webkit-animation-name:slideDown;
  -moz-animation-name:slideDown;
  -o-animation-name:slideDown;
  animation-name:slideDown;
}
@-webkit-keyframes slideUp {
  0% {
	-webkit-transform:translateY(0)
  }
  100% {
	-webkit-transform:translateY(-100%)
  }
}
@-moz-keyframes slideUp {
  0% {
	-moz-transform:translateY(0)
  }
  100% {
	-moz-transform:translateY(-100%)
  }
}
@-o-keyframes slideUp {
  0% {
	-o-transform:translateY(0)
  }
  100% {
	-o-transform:translateY(-100%)
  }
}
@keyframes slideUp {
  0% {
	transform:translateY(0)
  }
  100% {
	transform:translateY(-100%)
  }
}
.animated.slideUp {
  -webkit-animation-name:slideUp;
  -moz-animation-name:slideUp;
  -o-animation-name:slideUp;
  animation-name:slideUp;
}
@-webkit-keyframes swingInX {
  0% {
	-webkit-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(0deg)
  }
}
@-moz-keyframes swingInX {
  0% {
	-moz-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-moz-transform:perspective(400px) rotateX(0deg)
  }
}
@-o-keyframes swingInX {
  0% {
	-o-transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	-o-transform:perspective(400px) rotateX(0deg)
  }
}
@keyframes swingInX {
  0% {
	transform:perspective(400px) rotateX(-90deg)
  }
  100% {
	transform:perspective(400px) rotateX(0deg)
  }
}


@-webkit-keyframes flipInX {
  0% {
	-webkit-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
}
@-moz-keyframes flipInX {
  0% {
	-moz-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
  100% {
	-moz-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
}
@-o-keyframes flipInX {
  0% {
	-o-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
  100% {
	-o-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
}
@keyframes flipInX {
  0% {
	transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
  100% {
	transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
}
.animated.flipInX {
  -webkit-backface-visibility:visible !important;
  -webkit-animation-name:flipInX;
  -moz-backface-visibility:visible !important;
  -moz-animation-name:flipInX;
  -o-backface-visibility:visible !important;
  -o-animation-name:flipInX;
  backface-visibility:visible !important;
  animation-name:flipInX;
}
@-webkit-keyframes flipOutX {
  0% {
	-webkit-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
  100% {
	-webkit-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
}
@-moz-keyframes flipOutX {
  0% {
	-moz-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
  100% {
	-moz-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
}
@-o-keyframes flipOutX {
  0% {
	-o-transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
  100% {
	-o-transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
}
@keyframes flipOutX {
  0% {
	transform:perspective(400px) rotateX(0deg);
	opacity:1
  }
  100% {
	transform:perspective(400px) rotateX(90deg);
	opacity:0
  }
}
.animated.flipOutX {
  -webkit-animation-name:flipOutX;
  -webkit-backface-visibility:visible !important;
  -moz-animation-name:flipOutX;
  -moz-backface-visibility:visible !important;
  -o-animation-name:flipOutX;
  -o-backface-visibility:visible !important;
  animation-name:flipOutX;
  backface-visibility:visible !important;
}
@-webkit-keyframes bounceInDown {
  0% {
	opacity:0;
	-webkit-transform:translateY(-200px)
  }
  60% {
	opacity:1;
	-webkit-transform:translateY(30px)
  }
  80% {
	-webkit-transform:translateY(-10px)
  }
  100% {
	-webkit-transform:translateY(0)
  }
}
@-moz-keyframes bounceInDown {
  0% {
	opacity:0;
	-moz-transform:translateY(-200px)
  }
  60% {
	opacity:1;
	-moz-transform:translateY(30px)
  }
  80% {
	-moz-transform:translateY(-10px)
  }
  100% {
	-moz-transform:translateY(0)
  }
}
@-o-keyframes bounceInDown {
  0% {
	opacity:0;
	-o-transform:translateY(-200px)
  }
  60% {
	opacity:1;
	-o-transform:translateY(30px)
  }
  80% {
	-o-transform:translateY(-10px)
  }
  100% {
	-o-transform:translateY(0)
  }
}
@keyframes bounceInDown {
  0% {
	opacity:0;
	transform:translateY(-200px)
  }
  60% {
	opacity:1;
	transform:translateY(30px)
  }
  80% {
	transform:translateY(-10px)
  }
  100% {
	transform:translateY(0)
  }
}
.animated.bounceInDown {
  -webkit-animation-name:bounceInDown;
  -moz-animation-name:bounceInDown;
  -o-animation-name:bounceInDown;
  animation-name:bounceInDown;
}
@-webkit-keyframes bounceOutUp {
  0% {
	-webkit-transform:translateY(0)
  }
  30% {
	opacity:1;
	-webkit-transform:translateY(20px)
  }
  100% {
	opacity:0;
	-webkit-transform:translateY(-200px)
  }
}
@-moz-keyframes bounceOutUp {
  0% {
	-moz-transform:translateY(0)
  }
  30% {
	opacity:1;
	-moz-transform:translateY(20px)
  }
  100% {
	opacity:0;
	-moz-transform:translateY(-200px)
  }
}
@-o-keyframes bounceOutUp {
  0% {
	-o-transform:translateY(0)
  }
  30% {
	opacity:1;
	-o-transform:translateY(20px)
  }
  100% {
	opacity:0;
	-o-transform:translateY(-200px)
  }
}
@keyframes bounceOutUp {
  0% {
	transform:translateY(0)
  }
  30% {
	opacity:1;
	transform:translateY(20px)
  }
  100% {
	opacity:0;
	transform:translateY(-200px)
  }
}
.animated.bounceOutUp {
  -webkit-animation-name:bounceOutUp;
  -moz-animation-name:bounceOutUp;
  -o-animation-name:bounceOutUp;
  animation-name:bounceOutUp;
}

.headers-demonstration {
  .header--standard {
	margin-bottom: 50px;
	position: relative;
  }

  .footer {
	margin-bottom: 50px;
  }
}


/*------------- Responsive mode styles --------------*/

@media (max-width: 1350px) {
  .search-bar.w-search {
	max-width: 300px;
  }
}

@media (max-width: 1200px) {
  .header-menu a {
	padding: 30px 15px;
  }
}

@media (max-width: 1150px) {
  .author-page .author-name {
	display: none;
  }

  .author-page .more-dropdown {
	right: -8px;
	top: calc(100% + 10px);
  }
}

@media (max-width: 1080px) {

  .control-icon .more-dropdown {
	width: 320px;
	right: -140px;
  }

  .control-icon .notification-list .notification-icon {
	float: none;
	margin-top: 10px;
  }

  .control-icon .notification-list.friend-requests .accepted .notification-icon {
	float: right;
  }

  .header .page-title {
	display: none;
  }

  .header {
	padding-left: 70px;
  }

  .header.header--logout {
	padding-left: 0;
  }

  .header-content-wrapper {
	display: flex;
	justify-content: space-between;
  }

}

@media (max-width: 1024px) {
  .control-icon {
	margin-right: 20px;
  }

  .author-page {
	margin-left: 10px;
  }

  .header--standard {

	& > .container {
	  width: 100%;
	  max-width: unset;
	}
  }

  .header-menu a {
	padding: 30px 10px;
  }
}

@media (max-width: 960px) {
  .header--standard-wrap {
	flex-direction: column;
  }

  .header--standard .logo {
	margin-top: 10px;
  }

  .header--standard {
	padding: 10px 0;

	.header-menu {
	  margin-left: 0;
	}
  }
}

@media (max-width: 880px) {
  .header.header--logout .search-bar.w-search{
	display: none;
  }
}

@media (max-width: 801px) {
  .search-bar.w-search {
	max-width: 200px;
  }

  .top-header-author {
	position: relative;
	bottom: 20px;
	color: $body-font-color;

	.author-name {
	  color: $heading-color;
	}
  }

  .top-header-author {
	margin-top: 20px;
  }

  .profile-section {

	.control-block-button {
	  right: auto;
	  top: -30px;
	  left: 50%;
	  transform: translate(-50%, 0);
	  bottom: auto;
	  width: 100%;
	  text-align: center;
	}
  }

  .top-header.top-header-favorit .top-header-author {
	bottom: 0;
	top: auto;
	position: absolute;
  }

  .top-header.top-header-favorit {
	.profile-section {

	  .control-block-button {
		right: 38px;
		top: -31px;
		left: auto;
		transform: none;
		width: auto;
	  }
	}
  }
}

@media (max-width: 768px) {

  .header, .fixed-sidebar {
	display: none;
  }

  .header--standard-landing .header-menu li:last-child > a {
	padding-left: 25px;
  }

  .header--standard.headroom--not-top {
	padding: 5px;
  }

  .header-responsive,.fixed-sidebar.fixed-sidebar-responsive {
	display: block;
  }

  .header-responsive {
	.mobile-app-tabs .nav-link {
	  padding: 0 5px;
	}
  }

  .search-bar.w-search {
	width: 100%;
  }

  .tab-content-responsive {
	width: 100%;
	left: 0;
	display: block;
	position: absolute;
	z-index: -1;

	.ps > .ps__scrollbar-y-rail, .ps:hover > .ps__scrollbar-y-rail {
	  display: none;
	}
  }

  .view-all {
	position: relative;
  }

  .search-bar.w-search {
	max-width: 100%;
  }

  .fixed-sidebar-left.sidebar--small {
	height: 70px;
  }

  .control-block {
	float: none;
	display: block;
	height: auto;
	padding: 15px 20px 0;
  }

  .author-page .author-name {
	display: inline-block;
  }

  .control-block .author-title {
	color: $heading-color;
  }

  .control-block .author-thumb {
	position: relative;
  }

  .author-thumb .icon-status {
	border-color: $white-color !important;
  }

  .notification-list .notification-event {
	padding-left: 10px;
  }

  .friend-requests .chat-message-item {
	font-size: 10px;
  }

  .notification-list .notification-friend {
	font-size: 13px;
  }

  .notification-list.chat-message .notification-event {
	width: auto;
  }

  .notification-list.chat-message.chat-message-field .notification-event {
	max-width: 82%;
  }

  .popup-chat .notification-list.chat-message .notification-event {
	width: 75%;
  }

  .tab-content-responsive .ui-block-title a {
	color: $heading-color;
  }

  .tab-content-responsive .notification-list.friend-requests .selectize-dropdown .notification-icon {
	display: inline-block;
	float: right;
  }

  .profile-section {
	padding: 30px 0;
	z-index: 5;
  }

  .header--standard-landing .header-menu {
	background-color: $primary-color;
  }

  .header--standard-landing .header-menu .nav-item {
	display: inline-block;
	visibility: visible;
	opacity: 1;
  }

  .header--standard-landing .logo {
	float: none;
	justify-content: center;
	margin-top: 0;
  }

  .header--standard-landing .header-menu .nav-item:first-child a {
	padding-left: 25px;
  }

  .header--standard-landing .header-menu .nav-item.js-expanded-menu {
	display: none;
  }

  .header-menu .shoping-cart a .count-product {
	top: 10px;
  }

  .header--standard-wrap {
	flex-direction: row;
  }

  .header--standard .logo {
	margin-top: 0;
  }

  .header-menu {
	margin: auto;
	flex-direction: column;
	position: fixed;
	height: 100vh;
	width: 270px;
	transform: translateX(calc(100% + 70px));
	right: 0;
	top: 0;
	text-align: left;
	justify-content: baseline;
	align-items: baseline;
	padding-top: 50px;
	border-left: 1px solid $border-color;
	box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);

	a {
	  padding: 15px 25px;
	  color: $body-font-color;
	}

	ul {
	  display: block;
	}

	.show .dropdown-menu {
	  opacity: 1;
	  visibility: visible;
	  position: relative !important;
	  margin-bottom: 15px;
	  display: block;
	  transform: none !important;
	}

	& > * {
	  width: 100%;
	}
  }

  .open-responsive-menu {
	& > svg {
	  width: 20px;
	  height: 20px;
	}
  }

  .header--standard-dark {
	.header-menu {
	  border-left: 1px solid lighten($secondary-color, 10px);
	}
  }

  .header-menu .dropdown:hover > .dropdown-menu {
	opacity: 0;
	visibility: hidden;
  }

  .header-menu .show.dropdown:hover > .dropdown-menu {
	opacity: 1;
	visibility: visible;
  }

  .dropdown-menu {
	width: 100%;
	top: auto;
  }

  .header--standard .header-menu .dropdown-item {
	padding: 10px 30px;
  }

  .nav-item {
	width: 100%;
  }

  .open-responsive-menu {
	display: block;
  }

  .header--standard .header-menu .close-responsive-menu {
	display: block;
  }

  .header--standard {
	z-index: 31;
	height: 70px;

	.ps {
	  -ms-touch-action: auto;
	  touch-action: auto;
	  overflow: hidden !important;
	  -ms-overflow-style: none;
	}

	.ps:hover > .ps__scrollbar-x-rail, .ps:hover > .ps__scrollbar-y-rail {
	  display: block;
	}

	& > .container {
	  height: 100%;

	  & > .header--standard-wrap {
		height: 100%;
	  }
	}
  }

  .megamenu .row > * {
	width: 100%;
	max-width: 100%;

	& + * {
	  margin-top: 15px;
	}
  }

  .header--standard.headroom--not-top .header-menu .nav-link {
	padding: 15px 25px;
  }

  .dropdown-menu.megamenu {
	padding: 10px;
  }

  .dropdown-menu.megamenu .dropdown-item:hover {
	padding-left: 35px;
  }

  .header-spacer--standard {
	height: 70px;
  }

  .header--standard-landing.headroom--not-top .header-menu li .nav-link,
  .header--standard-landing.headroom--not-top .header-menu li .nav-link:hover {
	color: $white-color;
	fill: $white-color;
  }

}

@media (max-width: 570px) {
  .top-header.top-header-favorit .top-header-author {
	left: 20px;
  }
}

@media (max-width: 460px) {
  .tab-content-responsive .notification-list .notification-event {
	width: 50%;
  }

  .tab-content-responsive .notification-list .selectize-dropdown .notification-event {
	width: auto;
  }

  .top-header.top-header-favorit .top-header-author {
	bottom: auto;
	top: 0;
	margin-top: 5px;
  }

  .top-header.top-header-favorit .top-header-author .author-thumb {
	width: 60px;
	height: 60px;
	border-width: 2px;
  }

  .top-header.top-header-favorit .author-content {
	margin-top: 0;
  }

  .top-header.top-header-favorit .profile-menu {
	flex-direction: column;

	li {
	  margin-bottom: 5px;
	}
  }

  .control-icon .mCustomScrollbar {
	max-height: 150px;
  }
}

@media (max-width: 420px) {

  .top-header.top-header-favorit .author-name {
	font-size: 18px;
  }
}
