/*------------- #FOOTER --------------*/



.footer {
  width: calc(100% - 144px);
  margin: 0 auto;
  padding: 70px 55px 0;
  background-color: $white-color;
  box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
  position: relative;

  .widget .title {
	margin-bottom: 25px;
  }

  &.footer-full-width {
	width: 100%;
  }

  &.has-not-copyright {
	padding: 70px 55px;
  }
}

.footer--dark {
  background-color: $secondary-color;

  .w-about .logo {
	color: $white-color;

	.logo-title {
	  color: $white-color;
	}

	&:hover {
	  color: $white-color;
	}
  }

  .widget .title {
	color: $white-color;
  }

  .socials {

	i, svg {
	  color: $white-color;

	  &:hover {
		color: $primary-color;
	  }
	}
  }
}

.sub-footer-copyright {
  padding: 40px 0;
  text-align: center;
  border-top: 1px solid $border-color;
  margin-top: 60px;

  span {
	font-size: 12px;
  }

  a {
	color: $primary-color;
	opacity: .7;
	font-weight: 500;

	&:hover {
	  opacity: 1;
	}
  }
}


/*------------- Responsive mode styles --------------*/

@media (max-width: 1024px) {
  .footer .widget {
	margin-bottom: 40px;
  }

  .footer {
	padding: 40px 15px;
	width: 100%;
  }
}

@media (max-width: 768px) {
  .footer {
	text-align: center;
  }

  .footer .w-about .logo {
	display: inline-flex;
	margin-bottom: 10px;
  }

  .sub-footer-copyright {
	margin-top: 0;
	padding-bottom: 0;
  }
}
