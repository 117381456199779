/*------------- #W-SELECT --------------*/


.w-select {
  padding: 0 20px 0 0;

  .bootstrap-select > .dropdown-toggle {
	padding: 10px 15px;
	font-size: 12px;
	height: 40px;
	margin-bottom: 0;
  }

  .form-group {
	margin-bottom: 0;
	min-width: 180px;
  }

  .title {
	float: left;
	line-height: 40px;
	margin-right: 15px;
  }
}


/*------------- Responsive mode styles --------------*/

@media (max-width: 1400px) {
  .w-select .form-group {
	min-width: unset;
  }
}