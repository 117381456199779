/*------------- #TODAY-EVENTS --------------*/


.today-events {

  .control-block-button {
	right: 25px;
	top: -25px;
	z-index: 5;

	svg {
	  width: 20px;
	  height: 20px;
	}

	.olymp-dropdown-arrow-icon {
	  width: 6px;
	  height: 4px;
	}
  }

  .day-event {
	display: block !important;
	border-top: none;

	.card-header i, .card-header svg {
	  margin-top: 3px;
	  margin-left: 7px;
	  float: none;
	}
  }

  .card.checked {
	.event-time {
	  &:before {
		border-color: $blue;
	  }
	}
  }

  .event-time {
	position: relative;

	&:before {
	  content:'';
	  width: 9px;
	  height: 9px;
	  border: 2px solid $breez;
	  border-radius: 100%;
	  display: block;
	  position: absolute;
	  left: -31px;
	  top: 5px;
	  background-color: $white-color;
	}
  }

  .title {
	position: relative;
	padding-right: 30px;
  }

  .event-status-icon {
	position: absolute;
	top: 0;
	right: 0;
	fill: $body-font-color;

	&.completed {
	  fill: $breez;
	}

	&.checked {
	  fill: $blue;
	}

  }

  .event-time {
	margin-bottom: 10px;

	time {
	  font-size: 12px;
	}
  }

  &.calendar {
	.list {
	  margin-top: 0;
	  position: relative;
	  overflow: visible;
	}

	.card {
	  border-bottom: none;
	  padding-left: 25px;

	  &:before {
		display: block;
		content: '';
		width: 1px;
		height: 100%;
		background-color: $border-color;
		position: absolute;
		left: 25px;
		top: 0;
	  }

	  &:last-child {

		&:before {
		  height: 25px;
		}

		.card-header {
		  padding-bottom: 25px;
		}
	  }

	  &:first-child {

		.card-header {
		  padding-top: 25px;
		}
	  }
	}

	.day-event .card-header .event-status-icon {
	  i, svg {
		margin-top: 0;
		margin-left: 0;
		width: 20px;
		height: 20px;
	  }
	}

	.card-header {
	  padding: 15px 25px;

	  a {
		font-size: 14px;
	  }

	  .more-dropdown {
		right: auto;
		left: 0;

		a {
		  font-size: 12px;
		  &:hover {
			color: $breez;
		  }
		}
	  }

	  .olymp-dropdown-arrow-icon {
		width: 6px;
		height: 4px;
		margin-left: 5px;
	  }

	  .olymp-three-dots-icon {
		width: 16px;
		height: 4px;
		margin-left: 7px;
	  }

	}
  }

}

.today-events-thumb {
  position: relative;
  background-image: url("../img/bg-event-day.jpg");
  text-align: center;
  padding: 25px;
  color: $white-color;

  .day-number {
	font-size: 80px;
	line-height: 1;
	margin-bottom: 5px;
  }

  .day-week {
	font-size: 24px;
	margin-bottom: 5px;
  }

  .month-year {
	font-size: 12px;
	color: $body-font-color;
  }
}

.card-header a .olymp-dropdown-arrow-icon {
  width: 6px;
  height: 4px;
}