@import "theme-styles/variables";

@import "plugins/Tables-of-content";


/*------------- #THEME-STYLES --------------*/

@import "theme-styles/variables";
@import "theme-styles/mixins";
@import "theme-styles/header";
@import "theme-styles/footer";
@import "theme-styles/section";
@import "theme-styles/stunning-header";
@import "theme-styles/mobile-app";
@import "theme-styles/shop-cart";
@import "theme-styles/bootstrap-customization";


/*------------- #BLOCKS --------------*/

@import "blocks/ui-block";
@import "blocks/buttons";
@import "blocks/forms";
@import "blocks/fixed-sidebar";
@import "blocks/skills";
@import "blocks/friends";
@import "blocks/sliders";
@import "blocks/pagination";
@import "blocks/landing";
@import "blocks/registration-login-form";
@import "blocks/main-header";
@import "blocks/your-profile";
@import "blocks/notification";
@import "blocks/popup-chat";
@import "blocks/overlay-menu";
@import "blocks/popup-windows";
@import "blocks/ol-lists";
@import "blocks/friends-harmonic";
@import "blocks/posts";
@import "blocks/comment-list";
@import "blocks/post-video";
@import "blocks/video-player";
@import "blocks/news-feed-form";
@import "blocks/photo-gallery";
@import "blocks/features-video";
@import "blocks/event-item";
@import "blocks/available-widget";
@import "blocks/today-events";
@import "blocks/calendar-events-tabs";
@import "blocks/statistics";
@import "blocks/forums";
@import "blocks/open-topic-table";
@import "blocks/blog-posts";
@import "blocks/clients";
@import "blocks/heading";
@import "blocks/testimonials";
@import "blocks/info-boxes";
@import "blocks/counters";
@import "blocks/teammembers";
@import "blocks/socials";
@import "blocks/contacts";
@import "blocks/contact-form";
@import "blocks/accordion-faqs";
@import "blocks/table-careers";
@import "blocks/follow-instagram";
@import "blocks/help-support-block";
@import "blocks/search-help-result";
@import "blocks/shop";
@import "blocks/helper";
@import "blocks/video-background";
@import "blocks/search-results";
@import "blocks/range-slider";

/*------------- #WIDGETS --------------*/

@import "widgets/w-search";
@import "widgets/w-personal-info";
@import "widgets/w-socials";
@import "widgets/w-select";
@import "widgets/w-contacts";
@import "widgets/w-faved-page";
@import "widgets/w-twitter";
@import "widgets/w-latest-photo";
@import "widgets/w-playlist";
@import "widgets/w-friend-pages-added";
@import "widgets/w-create-fav-page";
@import "widgets/w-birthday-alert";
@import "widgets/w-action";
@import "widgets/w-activity-feed";
@import "widgets/w-wethear";
@import "widgets/w-last-video";
@import "widgets/w-pool";
@import "widgets/w-badges";
@import "widgets/w-blog-posts";
@import "widgets/w-about";
@import "widgets/w-list";
@import "widgets/w-featured-topics";
@import "widgets/w-build-fav";


/*------------- #PLUGINS --------------*/

@import "plugins/bootstrap-select.scss";
@import "plugins/daterangepicker.scss";
@import "plugins/fullcalendar.scss";
@import "plugins/jquery.mCustomScrollbar.min.scss";
@import "plugins/magnific-popup.scss";
@import "plugins/mediaelement-playlist-plugin.min.scss";
@import "plugins/mediaelementplayer.scss";
@import "plugins/simplecalendar.scss";
@import "plugins/swiper.min.scss";
@import "plugins/ion.rangeSlider";
@import "plugins/toastr.scss";
@import "plugins/loading.scss";
@import "plugins/cropper.scss";
